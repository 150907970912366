import { ActionMap, AuthState, AuthUser } from '../../types/AuthTypes'

export enum AUTH_ACTIONS {
  INITIALIZE = 'INITIALIZE',
  SIGN_OUT = 'SIGN_OUT',
}

type AuthActionTypes = {
  [AUTH_ACTIONS.INITIALIZE]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [AUTH_ACTIONS.SIGN_OUT]: undefined
}

export type CognitoActions = ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]

export const reducer = (state: AuthState, action: CognitoActions) => {
  if (action.type === AUTH_ACTIONS.INITIALIZE) {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  }
  if (action.type === AUTH_ACTIONS.SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    }
  }
  return state
}
