import { AccountType } from '../../../types/AccountType'
import classes from './Avatar.module.scss'

const Avatar = ({ avatar, name }: Pick<AccountType, 'avatar' | 'name'>) => (
  <div className={classes['avatar']} data-testid="avatar">
    <img src={avatar} alt={name} className={classes['avatar-img']} data-testid="image" />
  </div>
)

export default Avatar
