import { Card } from 'react-bootstrap'
import { ArrowUpRight } from 'react-feather'
import DashboardContainer from '../components/Dashboard/DashboardContainer'
import Help from '../components/Dashboard/Help/Help'
import useFetchDashboardData from '../components/Dashboard/useFetchDashboardData'

export default function PullRequestsPage() {
  const { loading, error, data } = useFetchDashboardData()

  return (
    <>
      <div className="p-0 container-fluid">
        <div className="mb-2 mb-xl-3 row align-items-end">
          <div className="d-none d-sm-block col-auto">
            <h1>Pull Requests Dashboard</h1>
          </div>
          <div className="ms-auto text-end me-1 col-auto">
            <a
              href="/pullrequests/full-view"
              className="d-flex gap-1 align-items-center text-black"
            >
              View full screen
              <ArrowUpRight />
            </a>
          </div>
        </div>
        <Card>
          <Card.Body>
            <DashboardContainer loading={loading} error={error} data={data} />
          </Card.Body>
        </Card>
      </div>
      {data && <Help bottom="20px" />}
    </>
  )
}
