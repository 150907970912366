import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BITBUCKET_INTEGRATION_CALLBACK_URL } from '../../constants'
import axios from 'axios'
import { getAuthorizationHeader } from '../../utils/getAuthorizationHeader'
import { useTranslation } from 'react-i18next'

const initiateOAuthCallback = async (code: string) => {
  const url = `${BITBUCKET_INTEGRATION_CALLBACK_URL}?code=${code}`
  const authorization = getAuthorizationHeader()
  const response = await axios.get(url, {
    headers: {
      authorization,
    },
  })

  if (response.status !== 200) {
    throw new Error('OAuth Callback request is not successful')
  }

  if (response.data?.message) {
    return { message: response.data?.message as string }
  }

  return {}
}

function OAuthBitbucketPage() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const code = searchParams.get('code')
    if (!code) {
      navigate('/integrations/bitbucket', {
        state: { error: t('something-wrong', { ns: 'common' }) },
      })
      return
    }

    initiateOAuthCallback(code)
      .then((res) => {
        if (res?.message) {
          const tKey = `errors.${res.message}`
          navigate('/integrations/bitbucket', {
            state: {
              error: t(tKey, {
                ns: 'bitbucket-integration-page',
                defaultValue: 'something-wrong',
              }),
            },
          })
        } else {
          navigate('/integrations/bitbucket', {
            state: {
              message: t('successfully-integrated', { ns: 'bitbucket-integration-page' }),
            },
          })
        }
      })
      .catch((e) => {
        console.error(e)
        navigate('/integrations/bitbucket', {
          state: {
            error: t('something-wrong', { ns: 'common' }),
          },
        })
      })
  }, [])

  return <></>
}

export default OAuthBitbucketPage
