import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'
import React from 'react'
import axiosInstance from '../../utils/axios'
import { getUserAttributes as getUserAttributesFn } from './getUserAttributes'
import { AUTH_ACTIONS, CognitoActions } from './reducer'

export const getGetSession =
  (
    UserPool: CognitoUserPool,
    getUserAttributes: typeof getUserAttributesFn,
    dispatch: React.Dispatch<CognitoActions>
  ) =>
  () =>
    new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser()
      if (user) {
        user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            reject(err)
          } else {
            if (!session) return

            const attributes = await getUserAttributes(user)
            const accessJwtToken = session.getAccessToken().getJwtToken()
            const idJwtToken = session.getIdToken().getJwtToken()

            localStorage.setItem('accessToken', accessJwtToken!)
            axiosInstance.defaults.headers.common.Authorization = idJwtToken!

            dispatch({
              type: AUTH_ACTIONS.INITIALIZE,
              payload: { isAuthenticated: true, user: attributes },
            })

            resolve({
              user,
              session,
              headers: { Authorization: idJwtToken },
            })
          }
        })
      } else {
        dispatch({
          type: AUTH_ACTIONS.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    })
