import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import useCountdownTimer from '../../hooks/useCountdownTimer'
import { TFunction } from 'i18next'
import { ConfirmRegistrationType } from '../../types/AuthTypes'

enum ERROR_MESSAGES {
  STATUS_IS_CONFIRMED = 'Current status is CONFIRMED',
  USER_ALREADY_CONFIRMED = 'User is already confirmed',
}

type OnConfirmType = () => void

type ConfirmRegistrationProps = {
  email: string
  onConfirm: OnConfirmType
  autoSendCode: boolean
}

type FormValuesType = {
  email: string
  code: string
  submit: boolean
}

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('form.errors.email.valid', { ns: 'common' }))
      .max(255)
      .required(t('form.errors.email.required', { ns: 'common' })),
    code: Yup.string()
      .max(6, ({ max }) => t('form.errors.code.max', { ns: 'common', max }))
      .required(t('form.errors.code.required', { ns: 'common' })),
  })

const getOnSubmit =
  (
    confirmRegistration: ConfirmRegistrationType,
    onConfirm: OnConfirmType,
    navigate: NavigateFunction,
    t: TFunction
  ) =>
  async (
    values: FormValuesType,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<FormValuesType>
  ) => {
    try {
      await confirmRegistration(values.email, values.code)
      onConfirm()
    } catch (error: any) {
      const message = error.message || t('something-wrong', { ns: 'common' })
      if (message.includes(ERROR_MESSAGES.STATUS_IS_CONFIRMED)) {
        navigate('/')
        return
      }
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

const RESEND_CODE_WAIT_TIME = 1000 * 30 // 30 seconds
const RESEND_CODE_TIME_INTERVAL = 1000 // 1 second

function ConfirmRegistration({ email, onConfirm, autoSendCode }: ConfirmRegistrationProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { resendConfirmationCode, confirmRegistration } = useAuth()
  const {
    countdown: timeLeft,
    isRunning,
    start,
    reset,
  } = useCountdownTimer({
    timer: RESEND_CODE_WAIT_TIME,
    interval: RESEND_CODE_TIME_INTERVAL,
  })

  const resendCode = async () => {
    try {
      await resendConfirmationCode(email)
      reset()
      start()
    } catch (e: any) {
      const error = e.message || ''
      if (error.includes(ERROR_MESSAGES.USER_ALREADY_CONFIRMED)) {
        onConfirm()
      }
    }
  }

  useEffect(() => {
    if (autoSendCode) {
      resendCode()
    }
  }, [])

  return (
    <Formik
      initialValues={{
        email,
        code: '',
        submit: false,
      }}
      validationSchema={getValidationSchema(t)}
      onSubmit={getOnSubmit(confirmRegistration, onConfirm, navigate, t)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>{t('form.labels.email-address', { ns: 'common' })}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={t('form.labels.email-address', { ns: 'common' })}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={true}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('form.labels.code', { ns: 'common' })}</Form.Label>
            <Form.Control
              type="text"
              name="code"
              placeholder={t('form.labels.code', { ns: 'common' })}
              value={values.code}
              isInvalid={Boolean(touched.code && errors.code)}
              onBlur={handleBlur}
              onChange={(event) => {
                handleChange(event)
                setFieldValue(event.target.name, event.target.value.replaceAll(' ', ''))
              }}
              disabled={isSubmitting}
            />
            {!!touched.code && (
              <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
            )}
            <Button
              variant="link"
              className="px-0"
              disabled={isRunning || isSubmitting}
              onClick={resendCode}
            >
              {!isRunning
                ? t('resend-code', { ns: 'verification-page' })
                : t('wait-for-seconds', { ns: 'verification-page', seconds: timeLeft / 1000 })}
            </Button>
          </Form.Group>
          <div className="text-center mt-3">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              <div className="d-flex align-items-center">
                {isSubmitting
                  ? t('form.buttons.verifiying', { ns: 'verification-page' })
                  : t('form.buttons.verify', { ns: 'verification-page' })}
                {isSubmitting && (
                  <Spinner animation="border" variant="light" size="sm" className="ms-3" />
                )}
              </div>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ConfirmRegistration
