import { Navigate, RouteObject } from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout'
import AuthLayout from './layouts/AuthLayout'
import NotFoundPage from './pages/NotFoundPage'
import PullRequestsPage from './pages/PullRequestsPage'
import DashboardPage from './pages/DashboardPage'
import SignUpPage from './pages/Auth/SignUpPage'
import VerificationPage from './pages/Auth/VerificationPage'
import { AutoVerifyPage } from './pages/Auth/AutoVerifyPage'
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage'
import ResetPasswordPage from './pages/Auth/ResetPasswordPage'
import SignInPage from './pages/Auth/SignIn'
import NotLoggedInGuard from './guards/NotLoggedInGuard'
import AuthGuard from './guards/AuthGuard'
import BitbucketPage from './pages/Integrations/BitbucketPage'
import OAuthBitbucketPage from './pages/OAuth/OAuthBitbucketPage'

export const routes: RouteObject[] = [
  {
    path: '/pullrequests/full-view',
    element: <DashboardPage />,
  },
  {
    path: '/oauth/bitbucket/callback',
    element: <OAuthBitbucketPage />,
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/pullrequests" />,
      },
      {
        path: 'pullrequests',
        element: <PullRequestsPage />,
      },
      {
        path: 'integrations',
        children: [
          {
            path: 'bitbucket',
            element: <BitbucketPage />,
          },
        ],
      },
      {
        path: 'documentation',
        children: [
          {
            path: '',
            element: <Navigate to="/documentation/setup" />,
          },
          {
            path: 'setup',
            element: <></>,
          },
          {
            path: 'dashboard',
            element: <></>,
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'signin',
        element: (
          <NotLoggedInGuard>
            <SignInPage />
          </NotLoggedInGuard>
        ),
      },
      {
        path: 'signup',
        element: (
          <NotLoggedInGuard>
            <SignUpPage />
          </NotLoggedInGuard>
        ),
      },
      {
        path: 'verification',
        element: <VerificationPage />,
      },
      {
        path: 'verify',
        element: <AutoVerifyPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]
