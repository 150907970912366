import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

export const getConfirmRegistration =
  (UserPool: CognitoUserPool) => (email: string, code: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      })

      user.confirmRegistration(code, true, (err) => {
        if (err) {
          reject(err)
          return
        }
        resolve(undefined)
      })
    })
