import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'

import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { getPasswordTest } from './-common/getPasswordTest'
import { TFunction } from 'i18next'
import { ConfirmPasswordType } from '../../types/AuthTypes'

type onSuccessType = () => void

type ResetPasswordArgs = {
  email: string
  code?: string
  onSuccess: onSuccessType
}

type FormValuesType = {
  email: string
  password: string
  code: string
  submit: boolean
}

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('form.errors.email.valid'))
      .required(t('form.errors.email.required')),
    password: Yup.string()
      .min(6, ({ min }) => t('form.errors.password.min', { min }))
      .test({
        name: 'PasswordPolicy',
        test: getPasswordTest(t),
      }),
    code: Yup.string()
      .max(6, ({ max }) => t('form.errors.code.max', { max }))
      .required(t('form.errors.code.required')),
  })

const getOnSubmit =
  (confirmPassword: ConfirmPasswordType, onSuccess: onSuccessType, t: TFunction) =>
  async (
    values: FormValuesType,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<FormValuesType>
  ) => {
    try {
      await confirmPassword(values.email, values.code, values.password)
      onSuccess()
    } catch (error: any) {
      const message = error.message || t('something-wrong')
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

function ResetPassword({ email, code = '', onSuccess }: ResetPasswordArgs) {
  const { t } = useTranslation('common')
  const { confirmPassword } = useAuth()

  return (
    <Formik
      initialValues={{
        email,
        password: '',
        code,
        submit: false,
      }}
      validationSchema={getValidationSchema(t)}
      onSubmit={getOnSubmit(confirmPassword, onSuccess, t)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>{t('form.labels.email-address')}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={t('form.labels.email-address')}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={true}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('form.labels.code')}</Form.Label>
            <Form.Control
              type="text"
              name="code"
              placeholder={t('form.labels.code')}
              value={values.code}
              isInvalid={Boolean(touched.code && errors.code)}
              onBlur={handleBlur}
              onChange={(event) => {
                handleChange(event)
                setFieldValue(event.target.name, event.target.value.replaceAll(' ', ''))
              }}
              disabled={!!code || isSubmitting}
            />
            {!!touched.code && (
              <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('form.labels.new-password')}</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder={t('form.labels.new-password')}
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              <div className="d-flex align-items-center">
                {isSubmitting ? t('form.buttons.submit.loading') : t('form.buttons.submit.normal')}
                {isSubmitting && (
                  <Spinner animation="border" variant="light" size="sm" className="ms-3" />
                )}
              </div>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPassword
