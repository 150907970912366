import PerfectScrollbar from 'react-perfect-scrollbar'
import SidebarNav from './SidebarNav'
import { SidebarItemsType } from '../../types/SidebarItemsType'
import useSidebar from '../../hooks/useSidebar'
import { NavLink } from 'react-router-dom'

interface SidebarProps {
  items: {
    title: string
    pages: SidebarItemsType[]
  }[]
  open?: boolean
}

const Sidebar = ({ items }: SidebarProps) => {
  const { isOpen } = useSidebar()

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <NavLink to="" className="sidebar-brand">
            <span className="align-middle me-3">PR Dashboard</span>
          </NavLink>
          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default Sidebar
