import React, { useState } from 'react'

import { SIDEBAR_POSITION, SIDEBAR_BEHAVIOR } from '../constants'
import useSettingsState from '../hooks/useSettingsState'

type SidebarContextType = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  setPosition: (position: string) => void
  setBehavior: (behavior: string) => void
  position: SIDEBAR_POSITION
  behavior: SIDEBAR_BEHAVIOR
}

const initialState: SidebarContextType = {
  isOpen: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPosition: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBehavior: () => {},
  position: SIDEBAR_POSITION.LEFT,
  behavior: SIDEBAR_BEHAVIOR.STICKY,
}

const SidebarContext = React.createContext<SidebarContextType>(initialState)

interface SidebarProviderType {
  children: React.ReactNode
}

function SidebarProvider({ children }: SidebarProviderType) {
  const [isOpen, setIsOpen] = useState(true)
  const [position, setPosition] = useSettingsState('sidebarPosition', SIDEBAR_POSITION.LEFT)
  const [behavior, setBehavior] = useSettingsState('sidebarBehavior', SIDEBAR_BEHAVIOR.STICKY)

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        position,
        setPosition,
        behavior,
        setBehavior,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export { SidebarProvider, SidebarContext }
