import { CognitoUser } from 'amazon-cognito-identity-js'

export const getUserAttributes = (currentUser: CognitoUser): Promise<Record<string, any>> =>
  new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) {
        reject(err)
      } else {
        const results: Record<string, any> = {}

        attributes?.forEach((attribute) => {
          results[attribute.Name] = attribute.Value
        })
        resolve(results)
      }
    })
  })
