import { Helmet } from 'react-helmet-async'
import { Card } from 'react-bootstrap'
import ConfirmRegistration from '../../components/Auth/ConfirmRegistration'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const VerificationPage = () => {
  const { t } = useTranslation('verification-page')
  const navigate = useNavigate()
  const { state } = useLocation()
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)

  const email = state?.email
  const autoSendCode = state?.autoSendCode || false

  useEffect(() => {
    if (!email) {
      navigate('/')
    }
  }, [])

  const onConfirm = () => setIsConfirmed(true)

  return (
    <>
      <Helmet title="Sign Up" />
      <div className="text-center">
        <h1 className="h2 mb-3">{isConfirmed ? t('heading.verified') : t('heading.normal')}</h1>
        {isConfirmed && <Link to="/auth/signin">{t('subheading.click-to-signin')}</Link>}
      </div>

      {!isConfirmed && (
        <Card>
          <Card.Body>
            <div className="m-sm-4">
              {email && (
                <ConfirmRegistration
                  email={email}
                  onConfirm={onConfirm}
                  autoSendCode={autoSendCode}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default VerificationPage
