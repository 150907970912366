import { DeveloperType } from '../../types/DeveloperType'
import { PullRequestType } from '../../types/PullRequestType'
import DashboardBody from './DashboardBody/DashboardBody'
import DashboardHeader from './DashboardHeader/DashboardHeader'
import classes from './Dashboard.module.scss'

interface DashboardProps {
  developers: DeveloperType[]
  pullRequests: PullRequestType[]
}

function Dashboard({ developers, pullRequests }: DashboardProps) {
  return (
    <div className={classes['dashboard-container']}>
      <div className={classes['dashboard-wrapper']}>
        <div className={classes['dashboard']}>
          <DashboardHeader
            logoUrl="/cloud-tools.png"
            logoText="pr dashboard"
            developers={developers}
          />
          <DashboardBody developers={developers} pullRequests={pullRequests} />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
