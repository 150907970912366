import { Icon } from '@iconify/react'
import classNames from 'classnames'
import classes from './ActionIcons.module.scss'

export const SelfApproveIcon = () => (
  <Icon
    icon="carbon:close-filled"
    className={classes['close-icon']}
    data-testid="action-icon-self-approve"
  />
)

export const ResolveOpenTasksIcon = ({ taskCount }: { taskCount: number }) => (
  <div className={classes['list']} data-testid="action-icon-resolve-open-tasks">
    <Icon icon="el:list-alt" className={classes['list__icon']} />
    <div className={classes['badge']}>{taskCount}</div>
  </div>
)

export const WaitIcon = () => (
  <Icon
    icon="game-icons:sands-of-time"
    className={classes['sand-timer-icon']}
    data-testid="action-icon-wait"
  />
)

export const ReadyToMergeIcon = () => (
  <Icon
    icon="fluent:merge-24-filled"
    className={classes['merge-icon']}
    data-testid="action-icon-ready-to-merge"
  />
)

export const IdleForReviewIcon = () => (
  <div className={classes['eye']} data-testid="action-icon-idle-for-review">
    <Icon icon="el:eye-open" className={classes['eye__icon']} />
  </div>
)

export const ApprovedIcon = () => (
  <div className={classes['check']} data-testid="action-icon-approved">
    <Icon icon="fa:check" className={classes['check__icon']} />
  </div>
)

export const ApprovedWithTasksIcon = ({ taskCount }: { taskCount: number }) => (
  <div
    className={classNames(classes['check'], classes['check--with-badge'])}
    data-testid="action-icon-approved-with-tasks"
  >
    <Icon icon="fa:check" className={classNames(classes['check__icon'], {})} />
    <div className={classes['badge']}>{taskCount}</div>
  </div>
)

export const UnderReviewIcon = () => (
  <div
    className={classNames(classes['eye'], classes['eye--red'])}
    data-testid="action-icon-under-review"
  >
    <Icon icon="el:eye-open" className={classes['eye__icon']} />
  </div>
)

export const UnderReviewWithTasksIcon = ({ taskCount }: { taskCount: number }) => (
  <div
    className={classNames(classes['eye'], classes['eye--with-badge'])}
    data-testid="action-icon-under-review-with-tasks"
  >
    <Icon icon="el:eye-open" className={classes['eye__icon']} />
    <div className={classes['badge']}>{taskCount}</div>
  </div>
)

export const AddYourselfAsReviewerIcon = () => (
  <Icon
    icon="ic:outline-question-mark"
    className={classes['question-mark']}
    data-testid="action-icon-add-yourself-as-reviewer"
  />
)
