import { Button, Spinner } from 'react-bootstrap'
import BitbucketURL from '../../assets/svgs/bitbucket.svg'
import { useTranslation } from 'react-i18next'

type LinkBitbucketAccountButtonProps = {
  isLoading?: boolean
  onClick: () => void
}

function LinkBitbucketAccountButton({
  isLoading = false,
  onClick,
}: LinkBitbucketAccountButtonProps) {
  const { t } = useTranslation('bitbucket-integration-page')

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <Button
          variant="light"
          size="lg"
          className="link-bitbucket-account"
          onClick={onClick}
          disabled={isLoading}
        >
          <div className="d-flex gap-3 align-items-center p-1">
            <img className="link-bitbucket-account__icon" src={BitbucketURL} />
            {t('link-account-btn')}
          </div>
        </Button>
      )}
    </>
  )
}

export default LinkBitbucketAccountButton
