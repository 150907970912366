import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { SidebarProvider } from './contexts/SidebarContext'
import { setContext } from '@apollo/client/link/context'
import { HelmetProvider } from 'react-helmet-async'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AuthProvider } from './contexts/CognitoContext'
import { routes } from './routes'
import { GRAPHQL_URI } from './constants'
import './styles/index.scss'
import './i18n'

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
})

const router = createBrowserRouter(routes)

function App() {
  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <SidebarProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </SidebarProvider>
      </ApolloProvider>
    </HelmetProvider>
  )
}

export default App
