const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_USER_POOL_CLIENT_ID } = process.env

if (!(REACT_APP_COGNITO_USER_POOL_ID && REACT_APP_COGNITO_USER_POOL_CLIENT_ID)) {
  throw new Error(
    'Envs: REACT_APP_COGNITO_USER_POOL_ID or/and REACT_APP_COGNITO_USER_POOL_CLIENT_ID is undefined'
  )
}

export const cognitoConfig = {
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  clientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
}
