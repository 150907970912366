import SignupPage from './en/SignupPage.json'
import Common from './en/Common.json'
import VerificationPage from './en/VerificationPage.json'
import ForgotPasswordPage from './en/ForgotPasswordPage.json'
import ResetPasswordPage from './en/ResetPasswordPage.json'
import SigninPage from './en/SigninPage.json'
import BitbucketIntegrationPage from './en/Integrations/BitbucketIntegrationPage.json'

const en = {
  common: Common,
  'signup-page': SignupPage,
  'verification-page': VerificationPage,
  'forgot-password-page': ForgotPasswordPage,
  'reset-password-page': ResetPasswordPage,
  'signin-page': SigninPage,
  'bitbucket-integration-page': BitbucketIntegrationPage,
}

export default en
