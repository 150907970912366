import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'

import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { VerifyEmail } from './-common/VerifyEmail'
import { getLocalizedErrorMessage } from './-common/getLocalizedErrorMessage'
import { TFunction } from 'i18next'
import { ResetPasswordType } from '../../types/AuthTypes'

enum RESPONSE_ERRORS {
  NOT_VERIFIED = 'Cannot reset password for the user as there is no registered/verified email or phone_number',
}

const ERROR_VALUE_MAPPING = new Map([[RESPONSE_ERRORS.NOT_VERIFIED, VerifyEmail]])
const LocalizedErrorMessage = getLocalizedErrorMessage(ERROR_VALUE_MAPPING)

type OnSuccesType = (email: string) => void

type ForgotPasswordProps = {
  onSuccess: OnSuccesType
}

type FormValuesType = {
  email: string
  submit: boolean
}

const initialValues: FormValuesType = {
  email: '',
  submit: false,
}

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('form.errors.email.valid', { ns: 'common' }))
      .max(255)
      .required(t('form.errors.email.required', { ns: 'common' })),
  })

const getOnSubmit =
  (resetPassword: ResetPasswordType, onSuccess: OnSuccesType, t: TFunction) =>
  async (
    values: FormValuesType,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<FormValuesType>
  ) => {
    try {
      await resetPassword(values.email)
      onSuccess(values.email)
    } catch (error: any) {
      const message = error.message || t('something-wrong', { ns: 'common' })
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

function ForgotPassword({ onSuccess }: ForgotPasswordProps) {
  const { resetPassword } = useAuth()
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(t)}
      onSubmit={getOnSubmit(resetPassword, onSuccess, t)}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">
                <LocalizedErrorMessage
                  error={errors.submit}
                  email={values.email}
                  namespace="forgot-password-page"
                />
              </div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>{t('form.labels.email-address', { ns: 'common' })}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder={t('form.placeholders.email', { ns: 'forgot-password-page' })}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              <div className="d-flex align-items-center">
                {isSubmitting
                  ? t('button.loading', { ns: 'forgot-password-page' })
                  : t('button.normal', { ns: 'forgot-password-page' })}
                {isSubmitting && (
                  <Spinner animation="border" variant="light" size="sm" className="ms-3" />
                )}
              </div>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPassword
