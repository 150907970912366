import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Card } from 'react-bootstrap'

import SignIn from '../../components/Auth/SignIn'
import { useTranslation } from 'react-i18next'

const SignInPage = () => {
  const { t } = useTranslation('signin-page')

  return (
    <React.Fragment>
      <Helmet title={t('page-title')} />
      <div className="text-center mt-4">
        <h2>{t('heading')}</h2>
        <p className="lead">{t('subheading')}</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default SignInPage
