import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

export const getSignIn =
  (UserPool: CognitoUserPool, getSession: () => Promise<unknown>) =>
  (email: string, password: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      })

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      })

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          getSession()
          resolve(data)
        },
        onFailure: (err) => {
          reject(err)
        },
        newPasswordRequired: () => {
          resolve({ message: 'New password required' })
        },
      })
    })
