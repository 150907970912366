import { SidebarItemsType } from '../../types/SidebarItemsType'

import { BookOpen, Sliders, Settings } from 'react-feather'

const pagesSection = [
  {
    href: '/',
    icon: Sliders,
    title: 'Dashboards',
    children: [
      {
        href: '/pullrequests',
        title: 'Pull Requests',
      },
    ],
  },
  {
    href: '#',
    icon: Settings,
    title: 'Integrations',
    children: [
      {
        href: '/integrations/bitbucket',
        title: 'Bitbucket',
      },
    ],
  },
  {
    href: '/documentation',
    icon: BookOpen,
    title: 'Documentation',
    children: [
      {
        href: '/documentation/setup',
        title: 'Setup',
      },
      {
        href: '/documentation/dashboard',
        title: 'Dashboard',
      },
    ],
  },
] as SidebarItemsType[]

const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
]

export default navItems
