import { createContext, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { AuthState, CognitoContextType } from '../types/AuthTypes'
import { cognitoConfig } from '../config'
import { getResendConfirmationCode } from './CognitoContext/getResendConfirmationCode'
import { getConfirmRegistration } from './CognitoContext/getConfirmRegistration'
import { getSignUp } from './CognitoContext/getSignup'
import { getSignIn } from './CognitoContext/getSignIn'
import { getUserAttributes as getUserAttributesFn } from './CognitoContext/getUserAttributes'
import { getGetSession } from './CognitoContext/getGetSession'
import { AUTH_ACTIONS, reducer } from './CognitoContext/reducer'
import { getConfirmPassword } from './CognitoContext/getConfirmPassword'
import { getResetPassword } from './CognitoContext/getResetPassword'

const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || '',
  ClientId: cognitoConfig.clientId || '',
})

const signUpFn = getSignUp(UserPool)
const resetPassowordFn = getResetPassword(UserPool)
const confirmPasswordFn = getConfirmPassword(UserPool)
const resendConfirmationCodeFn = getResendConfirmationCode(UserPool)
const confirmRegistrationFn = getConfirmRegistration(UserPool)

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const AuthContext = createContext<CognitoContextType | null>(null)

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getUserAttributes = useCallback(getUserAttributesFn, [])
  const getSession = useMemo(
    () => getGetSession(UserPool, getUserAttributes, dispatch),
    [getUserAttributes]
  )

  const initialize = useCallback(async () => {
    try {
      await getSession()
    } catch {
      dispatch({
        type: AUTH_ACTIONS.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }, [getSession])

  useEffect(() => {
    initialize()
  }, [initialize])

  const signIn = useMemo(() => getSignIn(UserPool, getSession), [getSession])

  const signOut = () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.signOut()
      dispatch({ type: AUTH_ACTIONS.SIGN_OUT })
    }
    localStorage.removeItem('accessToken')
  }

  const signUp = signUpFn
  const resetPassword = resetPassowordFn
  const confirmPassword = confirmPasswordFn
  const confirmRegistration = confirmRegistrationFn
  const resendConfirmationCode = resendConfirmationCodeFn

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: `${state?.user?.given_name || ''} ${state?.user?.family_name || ''}`,
          role: 'user',
          ...state.user,
        },
        signIn,
        signUp,
        signOut,
        confirmRegistration,
        resendConfirmationCode,
        resetPassword,
        confirmPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
