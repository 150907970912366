import { DeveloperType } from '../../../../types/DeveloperType'
import { PullRequestType } from '../../../../types/PullRequestType'
import ActionIcon from '../ActionIcon/ActionIcon'
import classes from './Actions.module.scss'

interface ActionsProps {
  developers: DeveloperType[]
  pullRequest: PullRequestType
}

function Actions({ developers, pullRequest }: ActionsProps) {
  const reviewers = developers.map((account) =>
    pullRequest.developers.find((reviewer) => reviewer.uuid === account.uuid)
  )

  return (
    <div className={classes['actions']}>
      {reviewers.map((reviewer, index) => (
        <div className={classes['action']} key={index}>
          {reviewer && (
            <ActionIcon actionIcon={reviewer.actionIcon} taskCount={pullRequest.taskCount} />
          )}
        </div>
      ))}
    </div>
  )
}

export default Actions
