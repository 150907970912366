import SignupPage from './de/SignupPage.json'
import Common from './de/Common.json'
import VerificationPage from './de/VerificationPage.json'
import ForgotPasswordPage from './de/ForgotPasswordPage.json'
import ResetPasswordPage from './de/ResetPasswordPage.json'
import SigninPage from './de/SigninPage.json'
import BitbucketIntegrationPage from './de/Integrations/BitbucketIntegrationPage.json'

const de = {
  common: Common,
  'signup-page': SignupPage,
  'verification-page': VerificationPage,
  'forgot-password-page': ForgotPasswordPage,
  'reset-password-page': ResetPasswordPage,
  'signin-page': SigninPage,
  'bitbucket-integration-page': BitbucketIntegrationPage,
}

export default de
