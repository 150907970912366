import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

export const getConfirmPassword =
  (UserPool: CognitoUserPool) => (email: string, code: string, password: string) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      })

      user.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
