import { ApolloError } from '@apollo/client'
import Dashboard from './Dashboard'
import { GET_DEVELOPERS_AND_PULL_REQUESTS_DATA } from './useFetchDashboardData'

type DashboardContainerProps = {
  loading: boolean
  error?: ApolloError
  data?: GET_DEVELOPERS_AND_PULL_REQUESTS_DATA
}

function DashboardContainer({ loading, error, data }: DashboardContainerProps) {
  if (loading || error || !data) {
    return (
      <div className="container overflow-auto">
        <div className="d-flex justify-content-center align-items-center my-4">
          {loading && <h2 className="text-success">Loading...</h2>}
          {error && <h2 className="text-danger">Failed to fetch the data</h2>}
          {!loading && !error && !data && <h2 className="text-danger">No data available</h2>}
        </div>
      </div>
    )
  }

  return <Dashboard developers={data.developers} pullRequests={data.pullRequests} />
}

export default DashboardContainer
