import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'

import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { getPasswordTest } from './-common/getPasswordTest'
import { TFunction } from 'i18next'
import { SignUpType } from '../../types/AuthTypes'

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(t('errors.first-name-required', { ns: 'signup-page' })),
    lastName: Yup.string().required(t('errors.last-name-required', { ns: 'signup-page' })),
    email: Yup.string()
      .email(t('form.errors.email.valid', { ns: 'common' }))
      .required(t('form.errors.email.required', { ns: 'common' })),
    password: Yup.string()
      .min(6, ({ min }) => t('form.errors.password.min', { ns: 'common', min }))
      .test({
        name: 'PasswordPolicy',
        test: getPasswordTest(t),
      }),
  })

type FormValuesType = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  submit: boolean
}

const initialValues: FormValuesType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  submit: false,
}

const getOnSubmit =
  (signUp: SignUpType, navigate: NavigateFunction, t: TFunction) =>
  async (
    values: FormValuesType,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<FormValuesType>
  ) => {
    try {
      await signUp(values.email, values.password, values.firstName, values.lastName)
      navigate('/auth/verification', { state: { email: values.email } })
    } catch (error: any) {
      const message = error.message || t('something-wrong', { ns: 'common' })
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

function SignUp() {
  const navigate = useNavigate()
  const { signUp } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(t)}
        onSubmit={getOnSubmit(signUp, navigate, t)}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                {errors.submit}
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>{t('form.labels.first-name', { ns: 'common' })}</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder={t('form.labels.first-name', { ns: 'common' })}
                value={values.firstName}
                isInvalid={Boolean(touched.firstName && errors.firstName)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              {!!touched.firstName && (
                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('form.labels.last-name', { ns: 'common' })}</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder={t('form.labels.last-name', { ns: 'common' })}
                value={values.lastName}
                isInvalid={Boolean(touched.lastName && errors.lastName)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              {!!touched.lastName && (
                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('form.labels.email-address', { ns: 'common' })}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder={t('form.labels.email-address', { ns: 'common' })}
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('form.labels.password', { ns: 'common' })}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder={t('form.labels.password', { ns: 'common' })}
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="text-center mt-3">
              <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                <div className="d-flex align-items-center">
                  {isSubmitting
                    ? t('button.loading', { ns: 'signup-page' })
                    : t('button.normal', { ns: 'signup-page' })}
                  {isSubmitting && (
                    <Spinner animation="border" variant="light" size="sm" className="ms-3" />
                  )}
                </div>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center mt-4">
        {t('existing-user', { ns: 'signup-page' })}{' '}
        <Link to="/auth/signin">{t('sign-in', { ns: 'signup-page' })}</Link>
      </div>
    </>
  )
}

export default SignUp
