import {
  AddYourselfAsReviewerIcon,
  ApprovedIcon,
  IdleForReviewIcon,
  ReadyToMergeIcon,
  ResolveOpenTasksIcon,
  SelfApproveIcon,
  UnderReviewIcon,
  UnderReviewWithTasksIcon,
  WaitIcon,
} from '../ActionIcons/ActionIcons'

type iconWithMessage = {
  IconComponent: (() => JSX.Element) | (({ taskCount }: { taskCount: number }) => JSX.Element)
  message: string
}

export const iconWithMessages: iconWithMessage[] = [
  {
    IconComponent: SelfApproveIcon,
    message: 'You are the author and need to self-approve your PR',
  },
  {
    IconComponent: ResolveOpenTasksIcon,
    message: 'You are the author and need to resolve tasks',
  },
  {
    IconComponent: WaitIcon,
    message: 'You are the author and need to wait for reviewers to review',
  },
  {
    IconComponent: ReadyToMergeIcon,
    message: 'You are the author and can merge your PR',
  },
  {
    IconComponent: IdleForReviewIcon,
    message: 'You are a reviewer but author has not yet self-approved',
  },
  {
    IconComponent: UnderReviewIcon,
    message: 'You are a reviewer and now have to review the PR',
  },
  {
    IconComponent: UnderReviewWithTasksIcon,
    message: 'You are a reviewer and the PR already has some tasks',
  },
  {
    IconComponent: ApprovedIcon,
    message: 'You have approved this PR',
  },
  {
    IconComponent: AddYourselfAsReviewerIcon,
    message: 'You are a potential reviewer, add yourself as a reviewer',
  },
]
