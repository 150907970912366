import { DeveloperType } from '../../../types/DeveloperType'
import AccountInfo from '../AccountInfo/AccountInfo'
import classes from './DashboardHeader.module.scss'

interface DashboardHeaderProps {
  logoUrl: string
  logoText: string
  developers: DeveloperType[]
}

function DashboardHeader({ logoUrl, logoText, developers }: DashboardHeaderProps) {
  return (
    <header className={classes['header']} data-testid="dashboard-header">
      <div className={classes['main']}>
        <div className={classes['logo']}>
          <img
            src={logoUrl}
            alt={logoText}
            className={classes['logo-img']}
            data-testid="dashboard-header-logo"
          />
        </div>
        <div className={classes['accounts']}>
          {developers.map((developer) => (
            <AccountInfo key={developer.uuid} {...developer} />
          ))}
        </div>
      </div>
    </header>
  )
}

export default DashboardHeader
