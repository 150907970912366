import * as Yup from 'yup'
import { AnyObject } from 'yup/lib/types'
import { TFunction } from 'i18next'

export const getPasswordTest =
  (t: TFunction) => (value: string | undefined, ctx: Yup.TestContext<AnyObject>) => {
    if (!value) {
      return ctx.createError({
        message: t('form.errors.password.required', { ns: 'common' }),
      })
    }

    const hasLowerCase = /(.*[a-z].*)/.test(value)
    if (!hasLowerCase) {
      return ctx.createError({
        message: t('form.errors.password.atleast-one-lowercase', { ns: 'common' }),
      })
    }

    const hasUpperCase = /(.*[A-Z].*)/.test(value)
    if (!hasUpperCase) {
      return ctx.createError({
        message: t('form.errors.password.atleast-one-uppercase', { ns: 'common' }),
      })
    }

    const hasDigits = /(.*\d.*)/.test(value)
    if (!hasDigits) {
      return ctx.createError({
        message: t('form.errors.password.atleast-one-digit', { ns: 'common' }),
      })
    }

    const hasSymbols = /(.*\W.*)/.test(value)
    if (!hasSymbols) {
      return ctx.createError({
        message: t('form.errors.password.atleast-one-symbol', { ns: 'common' }),
      })
    }

    return true
  }
