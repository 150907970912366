import DashboardContainer from '../components/Dashboard/DashboardContainer'
import Help from '../components/Dashboard/Help/Help'
import useFetchDashboardData from '../components/Dashboard/useFetchDashboardData'

function DashboardPage() {
  const { loading, error, data } = useFetchDashboardData()

  return (
    <>
      <DashboardContainer loading={loading} error={error} data={data} />
      {data && <Help />}
    </>
  )
}

export default DashboardPage
