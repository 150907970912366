import { PullRequestType } from '../../../types/PullRequestType'
import classes from './DashboardBody.module.scss'
import { DeveloperType } from '../../../types/DeveloperType'
import PullRequestDescription from '../PullRequestDescription/PullRequestDescription'
import Actions from './Actions/Actions'

interface DashboardBodyProps {
  developers: DeveloperType[]
  pullRequests: PullRequestType[]
}

function DashboardBody({ developers, pullRequests }: DashboardBodyProps) {
  return (
    <div className={classes['body']}>
      <div className={classes['pull-requests']}>
        {pullRequests.map((pullRequest) => (
          <PullRequestDescription key={pullRequest.uuid} pullRequest={pullRequest} />
        ))}
      </div>
      <div className={classes['developers-actions']}>
        {pullRequests.map((pullRequest) => (
          <Actions key={pullRequest.uuid} developers={developers} pullRequest={pullRequest} />
        ))}
      </div>
    </div>
  )
}

export default DashboardBody
