import { Helmet } from 'react-helmet-async'
import { Card } from 'react-bootstrap'

import SignUp from '../../components/Auth/SignUp'
import { useTranslation } from 'react-i18next'

const SignUpPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet title="Sign Up" />
      <div className="text-center mt-4 mb-2">
        <h1 className="h2">{t('heading', { ns: 'signup-page' })}</h1>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <SignUp />
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default SignUpPage
