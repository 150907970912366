import axios, { AxiosError, AxiosResponse } from 'axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) =>
    Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export default axiosInstance
