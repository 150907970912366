import LinkBitbucketAccountButton from '../../components/Buttons/LinkBitbucketAccountButton'
import { Button, Card, Spinner, Table } from 'react-bootstrap'
import { Copy } from 'react-feather'
import { useNotyf } from '../../hooks/useNotyf'
import { useTranslation } from 'react-i18next'
import useFetchBitbucketIntegration from './BitbucketPage/useFetchBitbucketIntegration'
import { BITBUCKET_INTEGRATION_INIT_URL } from '../../constants'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const getOAuthUrl = async () => {
  const token = localStorage.getItem('accessToken')

  const { data } = await axios.get<{ url: string }>(BITBUCKET_INTEGRATION_INIT_URL, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return data.url
}

function BitbucketPage() {
  const { t } = useTranslation()
  const { loading, error, data } = useFetchBitbucketIntegration()
  const notyf = useNotyf()
  const location = useLocation()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const bitbucket = data?.integrations?.bitbucket

  const copyWebhookUrl = () => {
    if (!navigator.clipboard || !bitbucket?.webhookUrl) return
    navigator.clipboard.writeText(bitbucket.webhookUrl)
    notyf.open({
      type: 'default',
      message: t('copied-to-clipboard', { ns: 'common' }),
      dismissible: true,
    })
  }

  const openSomethingWrongNotification = () => {
    notyf.open({
      type: 'danger',
      message: t('something-wrong', { ns: 'common' }),
      dismissible: true,
    })
  }

  const onClick = () => {
    setIsProcessing(true)
    getOAuthUrl()
      .then((url) => {
        if (url) {
          // redirects user to bitbucket authentication page
          window.location.href = url
        } else {
          setIsProcessing(false)
          openSomethingWrongNotification()
        }
      })
      .catch((e) => {
        console.error(e)
        setIsProcessing(false)
        openSomethingWrongNotification()
      })
  }

  useEffect(() => {
    if (!location.state) return

    const { message, error } = location.state
    if (message && typeof message === 'string') {
      notyf.open({
        type: 'success',
        message,
        dismissible: true,
      })
    }
    if (error && typeof error === 'string') {
      notyf.open({
        type: 'danger',
        message: error,
        dismissible: true,
        duration: 10000,
      })
    }
  }, [])

  return (
    <>
      {error && (
        <div className="d-flex align-items-center justify-content-center">
          <h2 className="text-danger">Failed to fetch data</h2>
        </div>
      )}
      {loading && (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {bitbucket?.hasIntegrated === false && (
        <>
          <h1>{t('headings.bitbucket', { ns: 'bitbucket-integration-page' })}</h1>
          <h5 className="pt-2">{t('no-linked-account', { ns: 'bitbucket-integration-page' })}</h5>
          <div className="p-5 container-fluid d-flex align-items-center justify-content-center">
            <LinkBitbucketAccountButton onClick={onClick} isLoading={isProcessing} />
          </div>
        </>
      )}
      {bitbucket?.hasIntegrated === true && (
        <>
          <section>
            <h1>{t('headings.bitbucket', { ns: 'bitbucket-integration-page' })}</h1>
            <div className="pt-2">
              <h5>{t('webhook-url', { ns: 'bitbucket-integration-page' })}</h5>
              <Button
                variant="light"
                className="p-0 text-secondary user-select-auto"
                onClick={() => copyWebhookUrl()}
              >
                <Card className="p-0 m-0">
                  <Card.Body className="py-2 px-3 d-flex justify-content-between align-items-center gap-4">
                    {bitbucket?.webhookUrl}
                    <Copy size={20} />
                  </Card.Body>
                </Card>
              </Button>
            </div>
          </section>
          <section className="pt-4">
            <h1 className="pb-2">
              {t('headings.linked-workspaces', { ns: 'bitbucket-integration-page' })}
            </h1>
            <Table borderless hover responsive variant="white" className="bg-white">
              <thead>
                <tr>
                  <th>{t('table-headings.name', { ns: 'bitbucket-integration-page' })}</th>
                  {/* <th>{t('table-headings.owner', { ns: 'bitbucket-integration-page' })}</th> */}
                </tr>
              </thead>
              <tbody>
                {bitbucket?.linkedWorkspaces?.map(({ id, name }) => (
                  <tr key={id}>
                    <td>{name}</td>
                    {/* <td>{owner}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </section>
        </>
      )}
    </>
  )
}

export default BitbucketPage
