import classes from './AccountInfo.module.scss'
import classNames from 'classnames'
import Avatar from '../../common/Avatar/Avatar'
import { dasherize } from '../../../utils/string'
import { DeveloperType } from '../../../types/DeveloperType'

function AccountInfo({ avatar, name, status }: Pick<DeveloperType, 'avatar' | 'name' | 'status'>) {
  return (
    <div className={classes['account']} data-testid="account-info">
      <Avatar avatar={avatar} name={name} />
      <div className={classes['name']} data-testid="account-info-name">
        {name}
      </div>
      <div
        className={classNames(classes['pill'], classes[`pill--${dasherize(status)}`])}
        data-testid="account-info-pill"
      ></div>
    </div>
  )
}

export default AccountInfo
