import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

export const getResendConfirmationCode = (UserPool: CognitoUserPool) => (email: string) =>
  new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    user.resendConfirmationCode((err) => {
      if (err) {
        reject(err)
        return
      }
      resolve(undefined)
    })
  })
