import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'
import { executeIfUnauthorized } from '../../../utils/executeIfUnauthorized'

export type LinkedWorkspace = {
  id: string
  name: string
  owner: string
}

export const GET_BITBUCKET_INTEGRATION = gql`
  query GetBitbucketIntegration {
    integrations {
      bitbucket {
        hasIntegrated
        webhookUrl
        linkedWorkspaces {
          name
        }
      }
    }
  }
`

export interface GET_BITBUCKET_INTEGRATION_DATA {
  integrations: {
    bitbucket: {
      hasIntegrated: boolean
      webhookUrl?: string
      linkedWorkspaces?: LinkedWorkspace[]
    }
  }
}

function useFetchBitbucketIntegration() {
  const { signOut } = useAuth()
  const { loading, error, data, refetch } =
    useQuery<GET_BITBUCKET_INTEGRATION_DATA>(GET_BITBUCKET_INTEGRATION)

  useEffect(() => {
    if (!error) return
    executeIfUnauthorized(error, () => {
      refetch().catch((e) =>
        executeIfUnauthorized(e, () => {
          signOut()
        })
      )
    })
  }, [error])

  return { loading, error, data }
}

export default useFetchBitbucketIntegration
