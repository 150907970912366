import { useEffect, useRef, useState } from 'react'

type useCountdownTimerProps = {
  timer: number
  interval: number
}

const useCountdownTimer = ({ timer, interval }: useCountdownTimerProps) => {
  const [countdown, setCountdown] = useState<number>(timer)
  const [isRunning, setIsRunning] = useState<boolean>(false)
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  const timerRef = useRef<number>(timer)

  useEffect(() => {
    if (!isRunning) return

    intervalRef.current = setInterval(() => {
      timerRef.current = timerRef.current - interval
      if (timerRef.current <= 0) {
        clearInterval(intervalRef.current)
        intervalRef.current = undefined
        setCountdown(0)
        setIsRunning(false)
      } else {
        setCountdown(timerRef.current)
      }
    }, interval)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isRunning])

  const start = () => {
    if (timerRef.current === 0 || isRunning) return
    setIsRunning(true)
  }

  const reset = () => {
    clearInterval(intervalRef.current)
    intervalRef.current = undefined
    timerRef.current = timer
    setCountdown(timer)
    console.log(timerRef, countdown)
  }

  return { countdown, isRunning, start, reset }
}

export default useCountdownTimer
