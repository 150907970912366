import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const NotLoggedInGuard = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated])

  return <>{children}</>
}

export default NotLoggedInGuard
