import { Outlet } from 'react-router-dom'
import Wrapper from '../components/Wrapper'
import Sidebar from '../components/Sidebar/Sidebar'
import Main from '../components/Main'
import Content from '../components/Content'

import dashboardItems from '../components/Sidebar/dashboardItems'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer'

const DashboardLayout = () => (
  <>
    <Wrapper>
      <Sidebar items={dashboardItems} />
      <Main>
        <Navbar />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Main>
    </Wrapper>
  </>
)

export default DashboardLayout
