import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

export const getResetPassword = (UserPool: CognitoUserPool) => (email: string) =>
  new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    user.forgotPassword({
      onSuccess: (data) => {
        resolve(data)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
