import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NamespacesType } from '../../../types/NamespacesType'

export type VerifyEmailProps = { email: string; namespace: keyof NamespacesType }

export const VerifyEmail = ({ email, namespace }: VerifyEmailProps) => {
  const { t } = useTranslation(namespace)

  return (
    <>
      {t('form.errors.email-to-be-verified.text')}
      <br />
      <Link to="/auth/verification" state={{ email, autoSendCode: true }}>
        {t('form.errors.email-to-be-verified.link')}
      </Link>
    </>
  )
}
