import { Helmet } from 'react-helmet-async'
import { Card } from 'react-bootstrap'
import ResetPassword from '../../components/Auth/ResetPassword'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const ResetPasswordPage = () => {
  const { t } = useTranslation('reset-password-page')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState<string>()
  const [code, setCode] = useState<string>()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

  useEffect(() => {
    const email = searchParams.get('email')
    if (email) {
      setEmail(email)
    } else {
      navigate('/')
    }

    const code = searchParams.get('code')
    if (code) {
      setCode(code)
    }
  }, [searchParams])

  const onSuccess = () => setIsSuccessful(true)

  return (
    <>
      <Helmet title={t('page-title')} />
      <div className="text-center mt-4">
        <h1 className="h2 mb-3">{isSuccessful ? t('successful') : t('heading')}</h1>
        {isSuccessful && <Link to="/auth/signin">{t('click-sign-in')}</Link>}
      </div>

      {!isSuccessful && (
        <Card>
          <Card.Body>
            <div className="m-sm-4">
              {email && <ResetPassword email={email} code={code} onSuccess={onSuccess} />}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default ResetPasswordPage
