import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

export const AutoVerifyPage = () => {
  const navigate = useNavigate()
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const { confirmRegistration } = useAuth()

  useEffect(() => {
    const email = searchParams.get('email')
    const code = searchParams.get('code')
    if (email && code) {
      confirmRegistration(email, code)
        .then(() => setIsConfirmed(true))
        .catch(() => navigate('/'))
    } else {
      navigate('/')
    }
  }, [])

  if (!isConfirmed) return <></>

  return (
    <>
      <Helmet title="Sign Up" />
      <div className="text-center">
        <h1 className="h2 mb-3">Your Email is verified</h1>
        <Link to="/auth/signin">Click here to Sign in</Link>
      </div>
    </>
  )
}
