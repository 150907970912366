import { gql, useQuery } from '@apollo/client'
import { DeveloperType } from '../../types/DeveloperType'
import { PullRequestType } from '../../types/PullRequestType'
import { useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { executeIfUnauthorized } from '../../utils/executeIfUnauthorized'

export const GET_DEVELOPERS_AND_PULL_REQUESTS = gql`
  query GetDevelopersAndPullRequests($workspaceUuid: String!) {
    developers(workspaceUuid: $workspaceUuid) {
      uuid
      name
      avatar
      status
    }
    pullRequests(workspaceUuid: $workspaceUuid) {
      uuid
      url
      title
      taskCount
      bitbucketUpdatedAt
      author {
        name
        avatar
        uuid
      }
      developers {
        uuid
        actionIcon
      }
      repository {
        name
        url
      }
    }
  }
`

export interface GET_DEVELOPERS_AND_PULL_REQUESTS_DATA {
  developers: DeveloperType[]
  pullRequests: PullRequestType[]
}

interface GET_DEVELOPERS_AND_PULL_REQUESTS_VARS {
  workspaceUuid: string
}

function useFetchDashboardData() {
  const { signOut } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    GET_DEVELOPERS_AND_PULL_REQUESTS_DATA,
    GET_DEVELOPERS_AND_PULL_REQUESTS_VARS
  >(GET_DEVELOPERS_AND_PULL_REQUESTS, {
    variables: { workspaceUuid: process.env.REACT_APP_WORKSPACE_UUID! },
  })

  useEffect(() => {
    if (!error) return
    executeIfUnauthorized(error, () => {
      refetch().catch((e) =>
        executeIfUnauthorized(e, () => {
          signOut()
        })
      )
    })
  }, [error])

  return { loading, error, data }
}

export default useFetchDashboardData
