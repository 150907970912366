import { PullRequestType } from '../../../types/PullRequestType'
import Avatar from '../../common/Avatar/Avatar'
import classes from './PullRequestDescription.module.scss'
import ago from 's-ago'
import { replaceWithNbsp } from '../../../utils/string'

interface PullRequestDescriptionProps {
  pullRequest: PullRequestType
}

function PullRequestDescription({ pullRequest }: PullRequestDescriptionProps) {
  const { author } = pullRequest
  const readableDateTime = replaceWithNbsp(ago(new Date(pullRequest.bitbucketUpdatedAt)))

  return (
    <div className={classes['description-wrapper']} data-testid="pullrequest-description">
      <div className={classes['description']}>
        <div className={classes['description__left']}>
          <Avatar avatar={author.avatar} name={author.name} />
          <a
            className={classes['repository-name']}
            href={pullRequest.repository.url}
            data-testid="pullrequest-description-repository"
          >
            {pullRequest.repository.name}
          </a>
        </div>
        <div className={classes['description__right']}>
          <a
            href={pullRequest.url}
            className={classes['title']}
            data-testid="pullrequest-description-title"
          >
            {pullRequest.title}
          </a>
          <div
            className={classes['updated-at-time']}
            data-testid="pullrequest-description-updated-time"
          >
            {readableDateTime}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PullRequestDescription
