import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'

export const getSignUp =
  (UserPool: CognitoUserPool) =>
  (email: string, password: string, firstName: string, lastName: string) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: 'email', Value: email }),
          new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstName,
          }),
          new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName,
          }),
        ],
        [],
        async (err) => {
          if (err) {
            reject(err)
            return
          }
          resolve(undefined)
          // Set destination URL here
          // window.location.href = ''
        }
      )
    })
