import { ErrorValueMapping } from '../types'
import { VerifyEmailProps } from './VerifyEmail'

type LocalizedErrorMessageArgs = {
  error: string
} & VerifyEmailProps

export const getLocalizedErrorMessage = (mapping: ErrorValueMapping) =>
  function LocalizedErrorMessage({ error, ...args }: LocalizedErrorMessageArgs): JSX.Element {
    const Value = mapping.get(error)

    if (Value) {
      if (typeof Value !== 'string') {
        return <Value {...args} />
      } else {
        return <>{Value}</>
      }
    }

    return <>{error}</>
  }
