import { ReviewerType } from '../../../../types/ReviewerType'
import {
  AddYourselfAsReviewerIcon,
  ApprovedIcon,
  ApprovedWithTasksIcon,
  IdleForReviewIcon,
  ReadyToMergeIcon,
  ResolveOpenTasksIcon,
  SelfApproveIcon,
  UnderReviewIcon,
  UnderReviewWithTasksIcon,
  WaitIcon,
} from '../../ActionIcons/ActionIcons'

type ActionIconProps = Pick<ReviewerType, 'actionIcon'> & {
  taskCount: number
}

function ActionIcon({ actionIcon, taskCount }: ActionIconProps) {
  switch (actionIcon) {
    case 'selfApprove':
      return <SelfApproveIcon />
    case 'resolveOpenTasks':
      return <ResolveOpenTasksIcon taskCount={taskCount} />
    case 'wait':
      return <WaitIcon />
    case 'readyToMerge':
      return <ReadyToMergeIcon />
    case 'addYourselfAsReviewer':
      return <AddYourselfAsReviewerIcon />
    case 'idleForReview':
      return <IdleForReviewIcon />
    case 'approvedWithTasks':
      return <ApprovedWithTasksIcon taskCount={taskCount} />
    case 'approved':
      return <ApprovedIcon />
    case 'underReview':
      return <UnderReviewIcon />
    case 'underReviewWithTasks':
      return <UnderReviewWithTasksIcon taskCount={taskCount} />
  }

  return <></>
}

export default ActionIcon
