import { Icon } from '@iconify/react'
import { useState, ReactNode } from 'react'
import { iconWithMessages } from './iconWithMessages'
import classes from './Help.module.scss'

type IconWithMessageProps = {
  message: string
  children: ReactNode
}

const IconWithMessage = ({ message, children }: IconWithMessageProps) => (
  <div className={classes['message']} data-testid="help-message">
    <span className={classes['icon-wrapper']}>{children}</span>
    {message}
  </div>
)

type HelpProps = {
  bottom?: string
  right?: string
}

function Help({ bottom = '30px', right = '30px' }: HelpProps) {
  const [isOpened, setIsOpened] = useState(false)

  const close = () => setIsOpened(false)
  const open = () => setIsOpened(true)

  const onClick = () => !isOpened && open()
  const onBlur = () => isOpened && close()

  return (
    <div
      className={classes['help']}
      style={{ bottom, right }}
      tabIndex={0}
      onClick={onClick}
      onBlur={onBlur}
      data-testid="help"
    >
      {isOpened ? (
        <div className={classes['body']} data-testid="help-popup">
          <Icon
            icon="material-symbols:close-rounded"
            className={classes['close-icon']}
            onClick={close}
            data-testid="help-popup-close-icon"
          />
          {iconWithMessages.map(({ IconComponent, message }, index) => (
            <IconWithMessage message={message} key={index}>
              <IconComponent taskCount={3} />
            </IconWithMessage>
          ))}
        </div>
      ) : (
        <div className={classes['button']} data-testid="help-button">
          <Icon
            icon="bi:info-circle-fill"
            className={classes['button__icon']}
            data-testid="help-button-icon"
          />
          Help
        </div>
      )}
    </div>
  )
}

export default Help
