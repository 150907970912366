import { ApolloError, ServerError } from '@apollo/client'

export const executeIfUnauthorized = (error: ApolloError, callback: () => void) => {
  if (error && error.networkError && error.networkError.name === 'ServerError') {
    const { statusCode } = error.networkError as ServerError
    if (statusCode === 401) {
      callback()
    }
  }
}
