import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { getLocalizedErrorMessage } from './-common/getLocalizedErrorMessage'
import { VerifyEmail } from './-common/VerifyEmail'
import { TFunction } from 'i18next'
import { SignInType } from '../../types/AuthTypes'

enum RESPONSE_ERRORS {
  NOT_VERIFIED = 'User is not confirmed.',
}

const ERROR_VALUE_MAPPING = new Map([[RESPONSE_ERRORS.NOT_VERIFIED, VerifyEmail]])
const LocalizedErrorMessage = getLocalizedErrorMessage(ERROR_VALUE_MAPPING)

type FormValuesType = {
  email: string
  password: string
  submit: boolean
}

const initialValues: FormValuesType = {
  email: '',
  password: '',
  submit: false,
}

const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('form.errors.email.valid', { ns: 'common' }))
      .required(t('form.errors.email.required', { ns: 'common' })),
    password: Yup.string().required(t('form.errors.password.required', { ns: 'common' })),
  })

const getOnSubmit =
  (signIn: SignInType, navigate: NavigateFunction, t: TFunction) =>
  async (
    values: FormValuesType,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<FormValuesType>
  ) => {
    try {
      await signIn(values.email, values.password)
      navigate('/')
    } catch (error: any) {
      const message = error.message || t('something-wrong', { ns: 'common' })
      setStatus({ success: false })
      setErrors({ submit: message })
      setSubmitting(false)
    }
  }

function SignIn() {
  const navigate = useNavigate()
  const { signIn } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(t)}
        onSubmit={getOnSubmit(signIn, navigate, t)}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">
                  <LocalizedErrorMessage
                    error={errors.submit}
                    email={values.email}
                    namespace="signin-page"
                  />
                </div>
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>{t('form.labels.email-address', { ns: 'common' })}</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                name="email"
                placeholder={t('form.placeholders.email', { ns: 'signin-page' })}
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('form.labels.password', { ns: 'common' })}</Form.Label>
              <Form.Control
                size="lg"
                type="password"
                name="password"
                placeholder={t('form.placeholders.password', { ns: 'signin-page' })}
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              )}
              <small>
                <Link to="/auth/forgot-password">
                  {t('forgot-password', { ns: 'signin-page' })}
                </Link>
              </small>
            </Form.Group>

            <div className="text-center mt-3">
              <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                <div className="d-flex align-items-center">
                  {isSubmitting
                    ? t('button.loading', { ns: 'signin-page' })
                    : t('button.normal', { ns: 'signin-page' })}
                  {isSubmitting && (
                    <Spinner animation="border" variant="light" size="sm" className="ms-3" />
                  )}
                </div>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center mt-4">
        {t('new-user', { ns: 'signin-page' })}{' '}
        <Link to="/auth/signup">{t('sign-up', { ns: 'signin-page' })}</Link>
      </div>
    </>
  )
}

export default SignIn
