import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Card } from 'react-bootstrap'

import ForgotPassword from '../../components/Auth/ForgotPassword'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const [isSuccessful, setIsSuccessful] = useState<boolean>()
  const [email, setEmail] = useState<string>()

  const onSuccess = (email: string) => {
    setIsSuccessful(true)
    setEmail(email)
  }

  return (
    <React.Fragment>
      <Helmet title={t('page-title', { ns: 'forgot-password-page' })} />
      <div className="text-center mt-4">
        {isSuccessful ? (
          <>
            <h1 className="h2">{t('successful', { ns: 'forgot-password-page' })}</h1>
            <Link to={`/auth/reset-password?email=${email}`}>
              {t('click-to-password-reset', { ns: 'forgot-password-page' })}
            </Link>
          </>
        ) : (
          <>
            <h1 className="h2">{t('heading', { ns: 'forgot-password-page' })}</h1>
            <p className="lead">{t('subheading', { ns: 'forgot-password-page' })}</p>
          </>
        )}
      </div>

      {!isSuccessful && (
        <Card>
          <Card.Body>
            <div className="m-sm-4">
              <ForgotPassword onSuccess={onSuccess} />
            </div>
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  )
}

export default ForgotPasswordPage
