import React from 'react'
import classNames from 'classnames'

interface MainProps {
  className?: string
  children: React.ReactNode
}

const Main = ({ className, children }: MainProps) => (
  <div className={classNames('main', className)}>{children}</div>
)

export default Main
